import { useDispatch, useSelector } from 'react-redux';

import { oauthApi, usersApi } from '../services/api';
import { resetUserData } from '../store/userSlice';

export const useLogout = () => {
  const dispatch = useDispatch();
  const {
    user: { csrfToken },
  } = useSelector((state) => state);
  const [oauthLogout] = oauthApi.useLogoutMutation();

  const logout = () =>
    oauthLogout({ csrf_token: csrfToken }).then(() => {
      dispatch(usersApi.util.resetApiState());
      dispatch(resetUserData());
    });

  return [logout];
};
