import { createSlice } from '@reduxjs/toolkit';

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    clientId: '',
    formToggles: {
      birthdayRequired: true,
      birthdayVisible: true,
      countryRequired: true,
      countryVisible: true,
      firstNameRequired: true,
      firstNameVisible: true,
      fullNameRequired: false,
      fullNameVisible: false,
      genderRequired: true,
      genderVisible: true,
      lastNameRequired: true,
      lastNameVisible: true,
      newsletterOptVisible: true,
    },
  },
  reducers: {
    setClientInfo: (state, action) => {
      state.clientId = action.payload.clientId;
      state.formToggles = {
        ...state.formToggles,
        ...action.payload.formToggles,
      };
    },
  },
});

export const { setClientInfo } = clientSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectCount = state => state.counter.value;

export default clientSlice.reducer;
