import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const legalUrls = {
  'fr-CA': {
    asicsTermsUrl: 'https://www.asics.com/ca/terms-of-use',
    cookiePolicyUrl: 'https://www.asics.com/ca/en-ca/cookie-policy',
    oneasicsTermsUrl: 'https://www.asics.com/ca/terms-of-use',
    privacyPolicyUrl: 'https://www.asics.com/ca/privacy-policy',
  },
  'en-CA': {
    asicsTermsUrl: 'https://www.asics.com/ca/terms-of-use',
    cookiePolicyUrl: 'https://www.asics.com/ca/en-ca/cookie-policy',
    oneasicsTermsUrl: 'https://www.asics.com/ca/terms-of-use',
    privacyPolicyUrl: 'https://www.asics.com/ca/privacy-policy',
  },
  'en-GB': {
    asicsTermsUrl: 'https://www.asics.com/gb/en-gb/customer-service/terms/terms.html',
    cookiePolicyUrl: 'https://www.asics.com/privacy/privacy-policy.html',
    oneasicsTermsUrl: 'https://www.asics.com/gb/en-gb/mk/oneasics-terms-and-conditions',
    privacyPolicyUrl: 'https://www.asics.com/privacy/privacy-policy.html',
  },
  'en-NL': {
    asicsTermsUrl: 'https://www.asics.com/nl/en-nl/customer-service/terms/terms.html',
    cookiePolicyUrl: 'https://www.asics.com/privacy/privacy-policy.html',
    oneasicsTermsUrl: 'https://www.asics.com/nl/en-nl/mk/oneasics-terms-and-conditions',
    privacyPolicyUrl: 'https://www.asics.com/privacy/privacy-policy.html',
  },
  'ja-JP': {
    asicsTermsUrl: 'https://www.asics.com/jp/ja-jp/mk/support/guide/rules',
    cookiePolicyUrl: 'https://www.asics.com/jp/ja-jp/mk/support/guide/cookiepolicy/ajp',
    oneasicsTermsUrl: 'https://www.asics.com/jp/ja-jp/mk/support/guide/membershiptc',
    privacyPolicyUrl: 'https://legal.asics.com/jp-jp/legal/privacy-policy',
  },
  'ko-KR': {
    asicsTermsUrl: 'https://asics.co.kr/service/agreement',
    cookiePolicyUrl: 'https://asics.co.kr/service/cookie',
    oneasicsTermsUrl: 'https://asics.co.kr/service/agreement',
    privacyPolicyUrl: 'https://asics.co.kr/service/privacy',
  },
  'en-US': {
    asicsTermsUrl: 'https://www.asics.com/us/en-us/terms.html',
    ccpaUrl: 'https://www.asics.com/us/en-us/california-laws.html',
    cookiePolicyUrl: 'https://www.asics.com/us/en-us/cookie-policy.html',
    oneasicsTermsUrl: 'https://www.asics.com/us/en-us/mk/oneasics-terms-and-conditions',
    privacyPolicyUrl: 'https://www.asics.com/us/en-us/privacy-policy.html',
  },
  'en-NZ': {
    asicsTermsUrl: 'https://www.asics.com/nz/en-nz/terms-of-use',
    cookiePolicyUrl: 'https://www.asics.com/nz/en-nz/cookie-policy',
    oneasicsTermsUrl: 'https://www.asics.com/nz/en-nz/oneasics-terms-and-conditions',
    privacyPolicyUrl: 'https://www.asics.com/au/en-au/privacy-policy/privacy-policy.html',
  },
  'en-AU': {
    asicsTermsUrl: 'https://www.asics.com/au/en-au/terms-and-conditions/terms-and-conditions.html',
    oneasicsTermsUrl: 'https://www.asics.com/au/en-au/customer-service/oneasics-terms-and-conditions/',
    privacyPolicyUrl: 'https://www.asics.com/au/en-au/privacy-policy/privacy-policy.html',
  },
  'pt-BR': {
    asicsTermsUrl: 'https://www.asics.com.br/termos-e-condicoes',
    cookiePolicyUrl: 'https://www.asics.com.br/politica-de-cookies',
    oneasicsTermsUrl: 'https://www.asics.com.br/termos-e-condicoes',
    privacyPolicyUrl: 'https://www.asics.com.br/politica-de-privacidade',
  },
  'zh-CN': {
    cookiePolicyUrl: 'https://www.asics.com.cn/cookie-policy',
    privacyPolicyUrl: 'https://www.asics.com.cn/privacy-policy',
  },
  'en-MY': {
    asicsTermsUrl: 'https://www.asics.com/my/en-my/terms-and-conditions',
    cookiePolicyUrl: 'https://www.asics.com/my/en-my/cookies-policy',
    oneasicsTermsUrl: 'https://www.asics.com/my/en-my/terms-and-conditions',
    privacyPolicyUrl: 'https://www.asics.com/my/en-my/privacy-policy',
  },
  'zh-TW': {
    asicsTermsUrl: 'https://www.asics.com.tw/MyAccount/Privacy/41053',
    oneasicsTermsUrl: 'https://www.asics.com.tw/MyAccount/Privacy/41053',
    privacyPolicyUrl: 'https://www.asics.com.tw/MyAccount/Privacy/41053',
  },
  'th-TH': {
    asicsTermsUrl: 'https://www.asics.com/th/en-th/termsconditions',
    cookiePolicyUrl: 'https://www.asics.com/th/en-th/cookie-policy',
    oneasicsTermsUrl: 'https://www.asics.com/th/en-th/termsconditions',
    privacyPolicyUrl: 'https://www.asics.com/th/en-th/privacy-policy',
  },
  'vi-VI': {
    asicsTermsUrl: 'https://www.asics.com/vn/vi-vn/dieu-khoan-and-dieu-kie',
    cookiePolicyUrl: 'https://www.asics.com/vn/vi-vn/chinh-sach-cookie',
    oneasicsTermsUrl: 'https://www.asics.com/vn/vi-vn/dieu-khoan-and-dieu-kie',
    privacyPolicyUrl: 'https://www.asics.com/vn/vi-vn/chinh-sach-bao-mat',
  },
  'da-DK': {
    privacyPolicyUrl: 'https://legal.asics.com/da-dk/legal/privacy-policy?_ga=2.107633543.487521739.1688128170-1926102913.1688128170',
  },
  'fi-FI': {
    privacyPolicyUrl: 'https://legal.asics.com/fi-fi/legal/privacy-policy?_ga=2.107633543.487521739.1688128170-1926102913.1688128170',
  },
  'pl-PL': {
    privacyPolicyUrl: 'https://legal.asics.com/pl-pl/legal/privacy-policy?_ga=2.107633543.487521739.1688128170-1926102913.1688128170',
  },
  'pt-PT': {
    privacyPolicyUrl: 'https://legal.asics.com/pt-pt/legal/privacy-policy?_ga=2.107633543.487521739.1688128170-1926102913.1688128170',
  },
  'sv-SE': {
    privacyPolicyUrl: 'https://legal.asics.com/sv-se/legal/privacy-policy?_ga=2.107633543.487521739.1688128170-1926102913.1688128170',
  },
  'en-SG': {
    oneasicsTermsUrl: 'https://www.asics.com/sg/en-sg/mk/oneasicssg-terms-and-conditions',
  },
  'en-AE': {
    oneasicsTermsUrl: 'https://me.asics.com/en-ae/oneasics-terms-conditions?___country=ae&___lang=en&___store=ae',
  },
  'er-AE': {
    oneasicsTermsUrl: 'https://me.asics.com/er-ae/oneasics-terms-conditions?___country=ae&___lang=en&___store=ae',
  },
  'en-SA': {
    oneasicsTermsUrl: 'https://me.asics.com/en-sa/oneasics-terms-conditions?___country=ae&___lang=en&___store=ae',
  },
  'ar-SA': {
    oneasicsTermsUrl: 'https://me.asics.com/ar-sa/oneasics-terms-conditions?___country=ae&___lang=en&___store=ae',
  },
  'en-KW': {
    oneasicsTermsUrl: 'https://me.asics.com/en-kw/oneasics-terms-conditions?___country=ae&___lang=en&___store=ae',
  },
  'ar-KW': {
    oneasicsTermsUrl: 'https://me.asics.com/ar-kw/oneasics-terms-conditions?___country=ae&___lang=en&___store=ae',
  },
};

const clientLegalUrls = {
  runkeeper: {
    asicsTermsUrl: 'https://runkeeper.com/cms/terms-of-service/',
    cookiePolicyUrl: 'https://runkeeper.com/cms/cookie-policy/',
    privacyPolicyUrl: 'https://runkeeper.com/cms/privacy-policies/',
    supportUrl: 'runkeeper://deeplink?view=contactsupport',
  },
};

export const useLegalUrls = () => {
  const {
    client: { clientId },
    queryParams: { locale },
  } = useSelector((state) => state);
  const { i18n } = useTranslation();
  const currentLocale = locale || i18n.language;

  const defaultLegalUrls = {
    ...legalUrls['en-NL'],
    oneasicsTermsUrl: legalUrls['en-NL'].oneasicsTermsUrl,
  };

  return {
    ...defaultLegalUrls,
    ...(legalUrls[currentLocale] || {}),
    ...(clientLegalUrls[clientId] || {}),
  };
};
