import { useEffect, useRef } from 'react';

import { nanoid } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const THIRTY_MINUTES_IN_DAYS = 1 / 48; // 30 minutes expressed as a fraction of a day - 30 / (24 * 60) simplified

export const useSessionId = () => {
  const sessionId = useRef();

  useEffect(() => {
    const currentSessionId = Cookies.get('session_id');
    sessionId.current = currentSessionId || nanoid();

    if (!currentSessionId) {
      Cookies.set('session_id', sessionId.current, { expires: THIRTY_MINUTES_IN_DAYS });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return sessionId.current;
};
