import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { setPersistableQueryParams } from '../store/queryParamsSlice';

const authedRoutes = [
  'change-email',
  'change-password',
  'complete-profile',
  'continue',
  'delete',
  'logout',
  'profile',
  'welcome',
];

/**
 * React hook for bouncing users to the correct page depending on their auth state.
 */
export const useBounce = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    queryParams: { bounce },
    user: { isLoggedIn },
  } = useSelector((state) => state);

  useEffect(() => {
    const bounceRoute = `/${bounce}`;

    if (bounce && location.pathname !== bounceRoute) {
      if ((authedRoutes.includes(bounce) && isLoggedIn) || (!authedRoutes.includes(bounce) && !isLoggedIn)) {
        history.push(bounceRoute);
        dispatch(setPersistableQueryParams({ bounce: '' }));
      }
    }
  }, [bounce, location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return [];
};
