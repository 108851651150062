import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accountType: '',
  ageGateVerified: true,
  asicsId: '',
  csrfToken: '',
  email: '', // The email from the found account. Could be a social account.
  firstName: '',
  fullName: '',
  isLoggedIn: false,
  lastName: '',
  newEmail: '', // The email from the change-email page
  thirdPartyToken: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserData: () => initialState,
    resetWithoutAgeGate: (state) => ({
      ...initialState,
      ageGateVerified: state.ageGateVerified,
    }),
    setAgeGateVerifiedFalse: (state) => {
      state.ageGateVerified = false;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = true;
      state.asicsId = action.payload.asicsId;
      state.csrfToken = action.payload.csrfToken;
    },
    setEmail: (state, action) => {
      state.email = action.payload.email || state.email;
    },
    setNewEmail: (state, action) => {
      state.newEmail = action.payload.newEmail;
    },
    setUserData: (state, action) => {
      state.accountType = action.payload.accountType;
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.fullName = action.payload.fullName;
      state.lastName = action.payload.lastName;
      state.thirdPartyToken = action.payload.thirdPartyToken;
    },
    toggleAgeGateVerified: (state) => {
      state.ageGateVerified = !state.ageGateVerified;
    },
  },
});

export const {
  resetUserData,
  resetWithoutAgeGate,
  setAgeGateVerifiedFalse,
  setIsLoggedIn,
  setEmail,
  setNewEmail,
  setUserData,
  toggleAgeGateVerified,
} = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectCount = state => state.counter.value;

export default userSlice.reducer;
