import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useLogout } from './use-logout';
import { usersApi } from '../services/api';

/**
 * React hook to check access token validation. This should be one of the first actions the app should do to see
 * if the user is authenticated.
 */
export const useAuthCheck = () => {
  const dispatch = useDispatch();
  const [logout] = useLogout();
  const { isLoggedIn } = useSelector((state) => state.user);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(usersApi.endpoints.getUserInfo.initiate(undefined, { forceRefetch: true }))
        .unwrap()
        .catch(() => logout());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [];
};
