import { ENVIRONMENT_NAME } from '../utilities/constants';

const allowedClients = ['sfcc_us_asics_web'];

export const sendExtoleEvent = (clientId, { asicsId, country, email, firstName, lastName }) => {
  if (ENVIRONMENT_NAME === 'prod' && allowedClients.includes(clientId)) {
    const zone_id = `${Math.floor(1e9 * Math.random())}.1`;

    fetch('https://share.asics.com/zones/oneasics_subscribed', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        '::headers.Accept': 'text/javascript',
        '::headers.X-Extole-App': 'javascript_sdk',
        first_name: firstName,
        last_name: lastName,
        email,
        partner_user_id: asicsId,
        location: country,
        zone_id,
      }),
    });
  }
};

export const initExtole = (clientId, queryParams) => {
  const shareable_code = queryParams.get('extole_shareable_code');
  const share_channel = queryParams.get('extole_share_channel');

  if (ENVIRONMENT_NAME === 'prod' && allowedClients.includes(clientId) && shareable_code) {
    fetch('https://share.asics.com/zones/blank', {
      method: 'POST',
      headers: {
        Accept: 'text/javascript',
        'X-Extole-App': 'javascript_sdk',
      },
      credentials: 'include', // this is needed for cookies to be store in browser and sent later back to Extole
      body: JSON.stringify({ shareable_code, share_channel }),
    });
  }
};
