import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const { search } = useLocation();

  window.querystring = search; // TODO: remove this once we're done testing and https://runkeeper.atlassian.net/browse/RKM-6598 works

  return useMemo(() => new URLSearchParams(search), [search]);
};

export default useQueryParams;
