import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  emailFromLander: '', // The email entered on the Lander page for sign in or registration.
};

/**
 * A slice for keeping track of UI state. It is not persisted and will be cleared on page fresh.
 */
export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setEmailFromLander: (state, action) => {
      state.emailFromLander = action.payload;
    },
  },
});

export default uiSlice.reducer;
